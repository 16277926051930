<template>
<div id="scaffold">
  <vx-card>
    <div class="container-fluid">
      <div class="row w-full">
        <form-wizard
        color="rgba(var(--vs-primary), 1)"
        :title="null"
        :subtitle="null"
        ref="wizardForm"
        >
          <tab-content 
            v-for="tab in tabs"
            :key="tab.title"
            :title="tab.title"
            :icon="tab.icon"
            :before-change="tab.beforeChange"
            :ref="tab.ref"
          >
            <component :is="tab.component" @dataUpdated="tab.dataUpdated"></component>
          </tab-content>
          <template slot="footer" slot-scope="props">
            <div class="container-fluid mt-5 mx-4">
              <div class="vx-row justify-end">
                <div class="wizard-footer-left">
                  <vx-tooltip :text="$t('onBoarding.tooltip.previous')" v-if="props.activeTabIndex > 0">

                    <vs-button  class="mx-5" type="border" @click.native="props.prevTab(); trackCurrentTab()">{{$t('onBoarding.previous')}}</vs-button>

                  </vx-tooltip>
                </div>
                <div class="wizard-footer-right">
                  <vx-tooltip :text="$t('onBoarding.tooltip.nextStep')" v-if="!props.isLastStep">
                    <vs-button  @click.native="props.nextTab(); trackCurrentTab()" class="wizard-footer-right">{{$t('onBoarding.next')}}</vs-button>
                  </vx-tooltip>
                  <vx-tooltip :text="$t('onBoarding.tooltip.complete')" v-else>
                    <vs-button @click.native="finishOnBoarding" class="wizard-footer-right finish-button">  {{props.isLastStep ? $t('onBoarding.complete') : $t('onBoarding.next')}}</vs-button>
                  </vx-tooltip>
                </div>
              </div>
            </div>
          </template>
        </form-wizard>
      </div>
    </div>
  </vx-card>
</div>
</template>

<script>
import axios from '@/axios'
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard'
import VenueInformation from './VenueInformation.vue'
import BookingOptions from './BookingOptions.vue'
import AddOns from './AddOns.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import SimpleVueValidation from 'simple-vue-validator';
const Validator = SimpleVueValidation.Validator;

export default {
  validators: {
      'venue.name': function (value) {
          return this.$refs.wizardForm.activeTabIndex == 0 ? Validator.value(value).required('الحقل مطلوب') : Validator.value(value);
      },
  },
  data() {
    return {
      venue: JSON.parse(localStorage.getItem('currentVenue')),
      tabs: [
        {title: this.$t('onBoarding.venueInformation'), icon: 'feather icon-home', component: 'venue-information', beforeChange: this.venueInformationStep, dataUpdated: this.venueDataUpdated, ref: 'venueInformationRef'}, 
        {title: this.$t('onBoarding.bookingOptions') , icon: 'feather icon-book-open', component: 'booking-options' , beforeChange: this.emptyHandler, dataUpdated: this.venueDataUpdated, ref: 'bookingOptionsRef'}, 
        {title: this.$t('onBoarding.services') , icon: 'FA5 fas fa-tag', component: 'add-ons' , beforeChange: this.emptyHandler, dataUpdated: this.venueDataUpdated, ref: 'addOnsRef'}, 
      ],
    }
  },
  validators: {
    'venue.phone_number_without_country_key': function (value) {
        let numCount = Validator.value(value).regex('^5[0-9]{8}$', this.$t('venueInformationForm.phoneNumberNumbersCount'));
        let format = Validator.value(value).regex('^5[0-9]{0,50}$', this.$t('venueInformationForm.phoneNumberFormat'))
        if(format._messages.length > 0) {
          return format;
        } else {
          return numCount;
        }
    },
    'venue.whatsapp_number_without_country_key': function (value) {
        let numCount = Validator.value(value).regex('^5[0-9]{8}$', this.$t('venueInformationForm.phoneNumberNumbersCount'));
        let format = Validator.value(value).regex('^5[0-9]{0,50}$', this.$t('venueInformationForm.phoneNumberFormat'))
        if(format._messages.length > 0) {
          return format;
        } else {
          return numCount;
        }
    },
        
  },
  computed: {
    isOnBoarding() {
        let pathArray = window.location.href.split('/');
        return pathArray[pathArray.length - 1] == 'on-boarding';
    }
  },
  components: {
    FormWizard,TabContent,WizardButton,
    // components
    VenueInformation,BookingOptions,AddOns
  },
  created () {
    
    this.$store.dispatch('loader/loaderOff')
  },
  mounted() {
    this.navigateToTabFromLocalStorage()
  },
  methods: {
    venueInformationStep() {
      this.$validate()
      if(this.venue){
        if(this.validation.countErrors() == 0) {
          return new Promise((resolve, reject) => {
            const venueData = new FormData();
            let currentVenue = this.venue;
            Object.keys(currentVenue).forEach(function(key){
                if (currentVenue[key] === null) {
                    delete currentVenue[key]
                }
            });
            for (var key of Object.keys(currentVenue)) {
                venueData.append(key, currentVenue[key]);
            }
            if(this.isOnBoarding) venueData.append('source', 'OnBoarding'); else venueData.append('source', 'Normal');
            venueData.append('phone_number', this.venue.phone_number_key + this.venue.phone_number_without_country_key)
            venueData.append('whatsapp_number', this.venue.whatsapp_number_key + this.venue.whatsapp_number_without_country_key)

            this.$store.dispatch('loader/loaderOn')
            axios.post(`/venues/${this.venue.id}/update/info`, venueData)
            .then(response => {
              this.$store.dispatch('auth/changeVenue', response.data.venue)
              this.venueDataUpdated(response.data.venue);
              this.$vs.notify({
                  color:'success',
                  text: response.data.success_message || 'تم رفع طلبكم بنجاح'
              })
              resolve(true)
            })
            .catch(error => {
              let errs = Object.keys(error.response.data.errors).map((key) => error.response.data.errors[key])
              let text = '';
              errs.forEach(err => {
                  text += '<li>'+err[0]+'</li>'
              });
              this.$vs.notify({
                  color:'danger',
                  title: 'حدث خطأ ما!',
                  text: '<ul>'+text+'</ul>'
              });
                
              reject()
            })
            .finally(() => {
              this.$store.dispatch('loader/loaderOff')
            });
          })
        } else {
          this.$vs.notify({
            color:'danger',
            title: 'حدث خطأ ما!',
            text: 'الرجاء التأكد من صحة بيانات المكان'
          });
          return false;
        }
      } 
      return true

    },
    venueDataUpdated(venue) {
      this.venue = venue ? venue : JSON.parse(localStorage.getItem('currentVenue'));
    },
    emptyHandler() {
      this.trackCurrentTab();
      return true
    },
    trackCurrentTab() {
      let activeTab = this.$refs.wizardForm.activeTabIndex
      localStorage.setItem('onboardingActiveTab', activeTab)
    },
    navigateToTabFromLocalStorage() {
      let activeTab = localStorage.getItem('onboardingActiveTab');
      if(activeTab) {
        let wizard = this.$refs.wizardForm
        wizard.changeTab(0, Number(activeTab))
        if(!wizard.isLastStep) {
          wizard.changeTab(0, Number(activeTab))
        }
      }
    },
    finishOnBoarding() {
      let onboardingStatus = this.venue.onboarding_status;
      this.venue.onboarding_status = 'completed';
      axios.post(`/venues/${this.venue.id}/complete/onBoarding`, this.venue)
      .then(response => {
        this.$store.dispatch('auth/changeVenue', response.data.venue)
        localStorage.removeItem('onboardingActiveTab')
        if(onboardingStatus == null || onboardingStatus == 'incompleted')
          this.$router.push(`/${this.venue.id}/on-boarding/thankyouPage`);
        else
          this.$router.push('/');
      })
      .catch(error => {
      })
      .finally(() => {
        this.$store.dispatch('loader/loaderOff')
      });
      
    },
    // skipTab() {
    //   let wizard = this.$refs.wizardForm
    //   if(wizard.isLastStep) this.finishOnBoarding()
    //   else wizard.changeTab(0,wizard.activeTabIndex + 1)
    // }
  }

}
</script>

<style lang="scss">
/*! rtl:begin:ignore */
#scaffold {
  .greet-user{
    position: relative;

    .decore-left{
      position: absolute;
      left:0;
      top: 0;
    }
    .decore-right{
      position: absolute;
      right:0;
      top: 0;
    }
  }

  @media(max-width: 576px) {
    .decore-left, .decore-right{
      width: 140px;
    }
  }
}

</style>