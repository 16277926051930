<template>
<div class="container-fluid">
    <booking-options-table />
</div>
</template>

<script>
import BookingOptionsTable   from '/src/views/components/qosoor-components/BookingOptionsTable.vue'
export default {
  data() {
 	return {
    
  }
 },
  components: {
    BookingOptionsTable
  },
 computed: {
    currentVenueId () {
        return this.$store.getters.currentVenueId
    },
    
  },
  
  created () {
    this.$store.dispatch('loader/loaderOff')
  },

}
</script>

<style>

</style>