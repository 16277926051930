<template>
    <div v-if="currentRoom != null">
        <vs-popup class="holamundo"  :title="!isEditRoom ? $t('editRoomForm.addRoom') : $t('editRoomForm.editRoom') +' | '+ currentRoom.name" :active.sync="isActive" @close="closePopup">
            <div class="container w-full">
                <div class="flex flex-wrap">
                    <div class="vx-col w-1/2 md:mb-2 pl-2 mt-2">
                        <vs-input :label="$t('editRoomForm.name')" v-model="currentRoom.name" class="w-full required" autocomplete="off"/>
                    </div>
                    <div class="vx-col w-1/2 md:mb-2 pl-2 mt-2">
                        <vs-input type="number" :label="$t('editRoomForm.count')" v-model="currentRoom.count" class="w-full"/>
                    </div>
                    <!-- WE MAY USE THESE LATER -->
                    <!-- <div class="vx-col w-1/2 md:mb-2 pl-2 mt-2">
                        <vs-input :label="$t('editRoomForm.description')" v-model="currentRoom.description" class="w-full" autocomplete="off"/>
                    </div>
                    <div class="vx-col w-full md:mb-2 pl-2 mt-2">
                        <vs-textarea :label="$t('editRoomForm.detailedDescription')" height="150px" v-model="currentRoom.detailed_description" class="w-full"/>
                    </div>
                    <div class="vx-col w-full md:mb-2 pl-2">
                        <label class="v-select-label">{{$t('editRoomForm.facilities')}}</label>
                        <v-select
                            multiple
                            class="v-select-background"
                            v-model="selectedFacilities" 
                            :options="facilitiesList" 
                            label="name"  
                            :dir="$vs.rtl ? 'rtl' : 'ltr'" 
                        />
                    </div>
                    <div class="vx-col w-full md:mb-2 pl-2 mt-2">
                        <div class="container mt-2">
                            <div class="vx-row">
                                <label class="v-select-label">{{$t('editRoomForm.genderType')}}</label>
                            </div>
                            <div class="vx-row mt-3">
                                <vs-radio class="mr-2" v-model="currentRoom.gender_type" vs-name="currentRoom.gender_type" vs-value="men">{{$t('editRoomForm.gender_type_radio.men')}}</vs-radio>
                                <vs-radio class="mr-2" v-model="currentRoom.gender_type" vs-name="currentRoom.gender_type" vs-value="women">{{$t('editRoomForm.gender_type_radio.women')}}</vs-radio>
                                <vs-radio class="mr-2" v-model="currentRoom.gender_type" vs-name="currentRoom.gender_type" vs-value="both">{{$t('editRoomForm.gender_type_radio.both')}}</vs-radio>
                            </div>
                        </div>
                    </div>
                    <div class="vx-col  w-1/2 md:mb-2 pl-2 mt-2">
                        <vs-input type="number" :label="$t('editRoomForm.size')" v-model="currentRoom.size" class="w-full"/>
                    </div> -->
                    
                    
                    <div class="vx-col w-full md:mb-2 pl-2 mt-4">
                        <vs-button icon-pack="feather" icon="icon-check" @click="editRoom" class="mx-2">{{$t('save')}}</vs-button>
                    </div>
                </div>
            </div>
        </vs-popup>
    </div>
</template>

<script>
import axios from '@/axios'
import vSelect from 'vue-select'

export default {
    name: 'room-form',
    props: {
        editRoomActive: {
            type: Boolean,
            defult: false
        },
        currentRoom: {
            type: Object,
            defult: null
        },
        isEditRoom: {
            type: Boolean,
            default: false
        },
    },
    data () {
        return {
            selectedFacilities: []
        }
    },
    components: {
        vSelect
    },
    computed: {
        isActive: {
            get() {
                return this.editRoomActive
            },
            set(newState) {
                return newState
            }
        },
        currentVenueId () {
            return this.$store.getters.currentVenueId;
        },
        facilitiesList () {
            return this.$store.state.general.facilitiesList;
        },
        isOnBoarding() {
            let pathArray = window.location.href.split('/');
            return pathArray[pathArray.length - 1] == 'on-boarding';
        }
    },
    created () {
        this.$store.dispatch('general/getFacilitiesList');
    },
    methods: {
        editRoom() {
            this.$store.dispatch('loader/loaderOn')

            let request = new FormData();

            for (var key in this.currentRoom ) {
                if(this.currentRoom[key] != null)
                    request.append(key, this.currentRoom[key]);
            }
            let source = this.isOnBoarding ? 'OnBoarding' : 'Normal';
            request.append('source', source);

            if(this.selectedFacilities && this.selectedFacilities.length > 0) this.selectedFacilities.forEach(facility => request.append('facilities[]', facility.id));
            
            let url = this.isEditRoom ? `/venues/${this.currentVenueId}/rooms/${this.currentRoom.id}` : `/venues/${this.currentVenueId}/rooms`;
            
            axios.post(url, request)
                .then((response) => {
                    this.closePopup();     
                    this.$parent.getRoomsAPI();
                    this.$store.dispatch('general/getRoomsList', this.currentVenueId) // update the rooms list state
                    this.$vs.notify({
                        color:'success',
                        text: response.data.success_message || 'تم الحفظ بنجاح'
                    })
                }).catch(error => {
                    this.errorsDisplay(error);
                })
                .finally(() => {
                    this.$store.dispatch('loader/loaderOff')
                }); // end of axios
        },
        
        closePopup() {
            this.$emit('update:editRoomActive', false);
        },
        errorsDisplay(error) {
            let errs = error.response.data.errors ? Object.keys(error.response.data.errors).map((key) => error.response.data.errors[key]) : []
            let text = '';
            errs.forEach(err => {
                text += '<li>'+err[0]+'</li>'
            });
            this.$vs.notify({
                color:'danger',
                type: 'error',
                title: 'حدث خطأ ما!',
                text: '<ul>'+text+'</ul>'
            });
        },
    },
    watch: {
        currentRoom(newVal) {
            this.selectedFacilities = newVal.facilities;
        },
    }
}
</script>

<style lang="css">
.edit-flex-col {
    justify-content: flex-end;
}
.vs-tooltip {
  z-index: 99999 !important; 
}

input[type=file] {
  padding: 7px 5px;
  border-radius: 8px;
}

input[type=file]::file-selector-button {
  border: none;
  padding: .75rem 2rem;
  border-radius: 6px;
  color: white;
  background-color: rgb(255,177,53);
  transition: 1s;
}

input[type=file]::file-selector-button:hover {
  background-color: rgb(248, 188, 91);
}

.required label::after {
    content: "*";
    color: red;
}

</style>
