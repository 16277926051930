<template>
<div class="container-fluid">
    <services-list />
</div>
</template>

<script>
// import AddOnsTable   from '/src/views/components/qosoor-components/AddOnsTable.vue'
import ServicesList from '../../components/Services/ServicesList.vue'
export default {
  data() {
 	return {
    
  }
 },
  components: {
    ServicesList
  },
 computed: {
    currentVenueId () {
        return this.$store.getters.currentVenueId
    },
    
  },
  
  created () {
    this.$store.dispatch('loader/loaderOff')
  },

}
</script>

<style>

</style>