<template>
<div class="container-fluid">
    <div class="vx-row">
      <div class="vx-col w-full mb-5">
          <h4>{{$t('venueInformation')}}</h4>
      </div>
      <div class="vx-col w-full lg:w-1/2 mb-2 required">
          <vs-input :label="$t('venueInformationForm.name')" v-model="venue.arabic_name" @keyup="enableSaveButton" class="w-full" autocomplete="off" :placeholder="$t('venueInformationForm.namePlaceholder')"/>
      </div>
      <div class="vx-col w-full lg:w-1/2 mb-2 required">
          <vs-input :label="$t('venueInformationForm.address')" v-model="venue.address" @keyup="enableSaveButton" class="w-full" autocomplete="off" :placeholder="$t('venueInformationForm.addressPlaceholder')"/>
      </div>
      <div class="vx-col w-full mb-2 mt-2">
          <vs-textarea :label="$t('venueInformationForm.about')" height="120px" @keyup="enableSaveButton" v-model="venue.about" class="w-full" :placeholder="$t('venueInformationForm.aboutPlaceholder')"/>
      </div>
      <div class="vx-col w-full mb-2 mt-2">
          <label class="v-select-label">{{$t('venueInformationForm.conditions')}}</label>
          <editor v-model="venue.conditions" theme="snow" :options="options"></editor>
      </div>
      <div class="vx-col w-full lg:w-1/3 mb-2 required">
          <vs-input :label="$t('venueInformationForm.capacityDescription')" v-model="venue.capacity_description" @keyup="enableSaveButton" class="w-full" autocomplete="off" :placeholder="$t('venueInformationForm.capacityDescriptionPlaceholder')"/>
      </div>

      <div class="vx-col w-full lg:w-1/3 md:mb-2">
        <!-- <vs-input :label="$t('venueInformationForm.phoneNumber')" v-model="venue.phone_number" @keyup="enableSaveButton" class="w-full" autocomplete="off" :placeholder="$t('venueInformationForm.phoneNumberPlaceholder')"/>-->
        <div class="flex">
          <div class="w-9/12 required">
            <vs-input :label="$t('venueInformationForm.phoneNumber')" dir="ltr" v-model="venue.phone_number_without_country_key" @keyup="enableSaveButton" class="w-full" autocomplete="off" :placeholder="$t('venueInformationForm.phoneNumberPlaceholder')" :danger="validation.hasError('venue.phone_number_without_country_key')" :danger-text="validation.firstError('venue.phone_number_without_country_key')"/>              
          </div>
          <div class="w-3/12">
            <v-select
                :clearable="false"
                class="v-select-background w-full mt-6"
                v-model="venue.phone_number_key"
                @input="enableSaveButton"
                :options="['+966']"
                dir="ltr"
            />
            <!-- <vs-input label=" " value="966" v-model="venue.phone_number_key" dir="rtl" class="w-full" disabled/> -->
          </div>
        </div>
      </div>
      <div class="vx-col w-full lg:w-1/3 md:mb-2">
          <div class="flex">
          <div class="w-9/12 required">
            <vs-input :label="$t('venueInformationForm.whatsappNumber')" dir="ltr" v-model="venue.whatsapp_number_without_country_key" @keyup="enableSaveButton" class="w-full" autocomplete="off" :placeholder="$t('venueInformationForm.phoneNumberPlaceholder')" :danger="validation.hasError('venue.whatsapp_number_without_country_key')" :danger-text="validation.firstError('venue.whatsapp_number_without_country_key')"/>
          </div>
          <div class="w-3/12">
            <v-select
                :clearable="false"
                class="v-select-background w-full mt-6"
                v-model="venue.whatsapp_number_key"
                @input="enableSaveButton"
                :options="['+966']"
                dir="ltr"
            />
          </div>
        </div>
      </div>

      <div class="vx-col w-full">
        <vs-divider />
      </div>

      <div class="vx-col w-full lg:w-1/2 mb-2 mt-5">
        <h4>{{$t('venueInformationForm.currentLogo')}}</h4>
        <img v-if="venue.logo" :src="`${imagesBaseURL}/${venue.logo}`" class="venue-logo border rounded max-w-sm"/>
        <p v-else class="text-danger">{{$t('venueInformationForm.noLogo')}}</p>
      </div>

      <div class="vx-col w-full lg:w-1/2 mb-2 mt-5">
        <h4>{{$t('venueInformationForm.uploadNewLogo')}}</h4>
        <vs-upload :action="`${baseURL}/venues/${venue.id}/uploadLogo`" :headers="headers" fileName="logo" :limit="1" automatic @on-success="logoChanged" @on-error="logoError"/>
      </div>

      <div class="vx-col w-full">
        <vs-divider />
      </div>

      <div class="vx-col w-full mb-4">
        <h4>{{$t('venueInformationForm.sections.pricings')}}</h4>
      </div>

      <div class="vx-col w-full lg:w-1/4 mb-2">
          <div class="container mt-2">
              <div class="vx-row required">
                  <label class="v-select-label">{{$t('venueInformationForm.taxingStatus')}}</label>
              </div>
              <div class="vx-row mt-3">
                  <vs-radio class="mr-2" v-model="venue.is_taxable" vs-name="venue.is_taxable" :vs-value="1">{{$t('venueInformationForm.selectText.taxable')}}</vs-radio>
                  <vs-radio class="mr-2" v-model="venue.is_taxable" vs-name="venue.is_taxable" :vs-value="0">{{$t('venueInformationForm.selectText.nonTaxable')}}</vs-radio>
              </div>

          </div>
      </div>
      <div class="vx-col w-full lg:w-1/4 mb-2">
          <div class="container mt-2">
              <div class="vx-row required">
                  <label class="v-select-label">{{$t('venueInformationForm.depositType')}}</label>
              </div>
              <div class="vx-row mt-3">
                  <vs-radio class="mr-2" v-model="venue.deposit_type" vs-name="venue.deposit_type" vs-value="fixed">{{$t('venueInformationForm.selectText.fixed')}}</vs-radio>
                  <vs-radio class="mr-2" v-model="venue.deposit_type" vs-name="venue.deposit_type" vs-value="percentage">{{$t('venueInformationForm.selectText.percentage')}}</vs-radio>
              </div>

          </div>
      </div>

      <div class="vx-col w-full lg:w-1/2 mb-2 required" v-if="venue.deposit_type == 'fixed'">
          <vs-input type="number" :label="$t('venueInformationForm.deposit')" v-model="venue.deposit" @keyup="enableSaveButton" class="w-full" autocomplete="off"/>
      </div>
      <div class="vx-col w-1/2 md:mb-2 required" v-else>
          <vs-input type="number" :label="$t('venueInformationForm.percentageDeposit')" v-model="venue.percentage_deposit" @keyup="enableSaveButton" class="w-full" autocomplete="off" :placeholder="$t('venueInformationForm.percentageDepositPlaceholder')"/>
      </div>


      <div class="vx-col w-full">
        <vs-divider />
      </div>
      <div class="vx-col w-full mb-4">
        <h4>{{$t('venueInformationForm.sections.commercialInformation')}}</h4>
      </div>

      <div class="vx-col w-full lg:w-1/3 mb-2">
          <vs-input :label="$t('venueInformationForm.commercialName')" v-model="venue.commercial_name" @keyup="enableSaveButton" class="w-full" autocomplete="off"/>
      </div>
      <div class="vx-col w-full lg:w-1/3 mb-2">
          <vs-input :label="$t('venueInformationForm.commercialRegistrationNumber')" v-model="venue.commercial_registration_number" @keyup="enableSaveButton" class="w-full" autocomplete="off"/>
      </div>
      <div class="vx-col w-full lg:w-1/3 mb-2">
          <vs-input :label="$t('venueInformationForm.vatNumber')" v-model="venue.vat_number" @keyup="enableSaveButton" class="w-full" autocomplete="off"/>
      </div>

      <div class="vx-col w-full lg:w-1/2 mb-2">
          <vs-input :label="$t('venueInformationForm.iban')" v-model="venue.iban" @keyup="enableSaveButton" class="w-full" autocomplete="off"/>
      </div>
      <div class="vx-col w-full lg:w-1/4 mb-2">
          <label class="v-select-label">{{$t('venueInformationForm.bankName')}}</label>
          <v-select
              :clearable="false"
              class="v-select-background"
              v-model="venue.bank_name"
              @input="enableSaveButton"
              :options="saudiBanksList"
              label="name"
              :reduce="bank => bank.name"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
          />
      </div>

      <div class="vx-col w-full lg:w-1/4 mb-2">
          <vs-input :label="$t('venueInformationForm.bankAccountHolderName')" v-model="venue.bank_account_holder_name" @keyup="enableSaveButton" class="w-full" autocomplete="off"/>
      </div>

      <div class="vx-col w-full">
        <vs-divider />
      </div>
      <div class="vx-col w-full mb-4">
        <h4>{{$t('venueInformationForm.sections.additionalInformation')}}</h4>
      </div>

      <div class="vx-col w-full md:mb-2 mt-2">
          <vs-textarea :label="$t('venueInformationForm.notesForAdmin')" height="120px" @keyup="enableSaveButton" v-model="venue.notes_for_admin" class="w-full" :placeholder="$t('venueInformationForm.notesForAdminPlaceholder')"/>
      </div>



      <div class="vx-col w-full">
        <div class="vx-row m-0 mb-5 justify-end">
            <vx-tooltip :text="isSaveDisabled ? $t('venueInformationForm.tooltip.disabledButtonVenueInformation') : $t('venueInformationForm.tooltip.saveVenueInformation')">
              <vs-button icon-pack="feather" icon="icon-check" @click="submitForm" :disabled="isSaveDisabled">{{$t('save')}}</vs-button>
            </vx-tooltip>
        </div>
      </div>
    </div>


  <div class="vx-row mt-10">
    <div class="vx-col  w-full md:mb-2">
      <rooms-table />
    </div>
  </div>
</div>
</template>

<script>
import axios from '@/axios'
import SaudiBanks from '/src/utils/KSABanks.json'
import RoomsTable   from '/src/views/components/qosoor-components/RoomsComponents/RoomsTable.vue'
import vSelect from 'vue-select'

import SimpleVueValidation from 'simple-vue-validator';
const Validator = SimpleVueValidation.Validator;

export default {
  data() {
    return {
      saudiBanksList: SaudiBanks,
      headers: {
        "Authorization": "Bearer " + localStorage.getItem("accessToken"),
        "Accept": "multipart/form-data"
      },
      options: {
        modules: {
            'toolbar': [
                [{ 'size': [] }],
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                [{ 'direction': 'rtl' }],
            ],
        },
      },
      venue: JSON.parse(localStorage.getItem('currentVenue')),
      isSaveDisabled: true,
    }
  },
  validators: {
    'venue.phone_number_without_country_key': function (value) {
        let numCount = Validator.value(value).regex('^5[0-9]{8}$', this.$t('venueInformationForm.phoneNumberNumbersCount'));
        let format = Validator.value(value).regex('^5[0-9]{0,50}$', this.$t('venueInformationForm.phoneNumberFormat'))
        if(format._messages.length > 0) {
          return format;
        } else {
          return numCount;
        }
    },
    'venue.whatsapp_number_without_country_key': function (value) {
        let numCount = Validator.value(value).regex('^5[0-9]{8}$', this.$t('venueInformationForm.phoneNumberNumbersCount'));
        let format = Validator.value(value).regex('^5[0-9]{0,50}$', this.$t('venueInformationForm.phoneNumberFormat'))
        if(format._messages.length > 0) {
          return format;
        } else {
          return numCount;
        }
    },
        
  },
  computed: {
    citiesList () {
        return this.$store.state.general.citiesList;
    },
    baseURL() {
      return process.env.VUE_APP_BASE_URL;
    },
    imagesBaseURL() {
      return process.env.VUE_APP_IMAGES_BASE_URL;
    },
    isOnBoarding() {
      let pathArray = window.location.href.split('/');
      return pathArray[pathArray.length - 1] == 'on-boarding';
    }
  },
  components: {
    RoomsTable,
    vSelect
  },
  created () {
    this.$store.dispatch('loader/loaderOff')
    this.$store.dispatch('general/getCitiesList')
  },
  methods: {
    submitForm() {
      this.$validate()
      if(this.validation.countErrors() == 0) {
        const venueData = new FormData();
        let currentVenue = this.venue;
        Object.keys(currentVenue).forEach(function(key){
            if (currentVenue[key] === null) {
                delete currentVenue[key]
            }
        });
        for (var key of Object.keys(currentVenue)) {
            venueData.append(key, currentVenue[key]);
        }
        if(this.isOnBoarding) venueData.append('source', 'OnBoarding'); else venueData.append('source', 'Normal');
        venueData.append('phone_number', this.venue.phone_number_key + this.venue.phone_number_without_country_key)
        venueData.append('whatsapp_number', this.venue.whatsapp_number_key + this.venue.whatsapp_number_without_country_key)
        
        axios.post(`/venues/${this.venue.id}/update/info`, venueData)
          .then(response => {
            this.$store.dispatch('auth/changeVenue', response.data.venue)
            this.isSaveDisabled = true
            this.$emit('dataUpdated', null)
            this.$vs.notify({
              color:'success',
              text: response.data.success_message || 'تم رفع طلبكم بنجاح'
            })
          })
          .catch(error => {
            let errs = Object.keys(error.response.data.errors).map((key) => error.response.data.errors[key])
            let text = '';
            errs.forEach(err => {
              text += '<li>'+err[0]+'</li>'
            });
            this.$vs.notify({
              color:'danger',
              title: 'حدث خطأ ما!',
              text: '<ul>'+text+'</ul>'
            });
  
          })
          .finally(() => {
            this.$store.dispatch('loader/loaderOff')
          });
      } else {
        this.$vs.notify({
          color:'danger',
          title: 'حدث خطأ ما!',
          text: 'الرجاء التأكد من صحة البيانات'
        });
      }
    },
    logoChanged(event) {
      let response = JSON.parse(event.target.response);
      this.$store.dispatch('auth/updateVenueLogo', response.venue.logo)
      this.venue.logo = response.venue.logo;
      this.$vs.notify({
          color:'success',
          text: response.success_message || 'تم تغيير الشعار بنجاح'
      })

    },
    logoError(event) {
      let response = JSON.parse(event.target.response);
      this.$vs.notify({
          color:'danger',
          title: 'حدث خطأ ما!',
          text: response.error_message
      })
    },
    enableSaveButton() {
      this.$emit('dataUpdated', this.venue)
      this.isSaveDisabled = false
    }
  }
}
</script>

<style>
.vs-image--img {
  height: 100% !important;
}
.venue-logo {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
</style>
