<template>
<div>

    <vx-card :title="$t('rooms')">
        <vs-table ref="table" :data="rooms" @selected="editRoom">
            <div slot="header" class="w-full mb-5 flex justify-end">
                <vs-button icon="add" @click="addRoom">{{ $t("Add")}}</vs-button>
            </div>
            <template slot="thead">
                <vs-th sort-key="name">{{$t('editRoomForm.name')}}</vs-th>
                <vs-th sort-key="category">{{$t('editRoomForm.count')}}</vs-th>
            </template>
            <template slot-scope="{data}">
                <tbody>
                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

                    <vs-td>
                        <p class="product-name font-medium truncate">{{ tr.name }}</p>
                    </vs-td>

                    <vs-td>
                        <p class="product-category">{{ tr.count }}</p>
                    </vs-td>

                    </vs-tr>
                </tbody>
            </template>
        </vs-table>
    </vx-card>

    <!-- Edit booking option popup -->
    <!-- <booking-option-form :editRoomActive.sync="editRoomActive" :currentRoom="currentRoom" @updatecurrentRoom="currentRoom = $event" :isEditRoom="isEditRoom" /> -->
    <room-form :editRoomActive.sync="editRoomActive" :currentRoom="currentRoom" :isEditRoom="isEditRoom"/>
</div>
</template>

<script>
import axios from '@/axios'
import ImageSlider   from '/src/views/q-pages/ImagesSlider.vue' // carousel
import RoomForm  from './RoomForm.vue'
export default {
data () {
    return {      
      rooms: [],

      editRoomActive: false,
      editBookingOptionPromptActive: false,
      currentRoom : {},
      isEditRoom: false,
    }
  },
  components: {
      RoomForm
  },
  computed: {
    currentVenueId () {
        return this.$store.getters.currentVenueId
    },
    isOnBoarding() {
        let pathArray = window.location.href.split('/');
        return pathArray[pathArray.length - 1] == 'on-boarding';
    },
  },
  created () {
    this.$store.dispatch('loader/loaderOn')
    this.getRoomsAPI();
    
  },
  methods: {
    getRoomsAPI() {
      axios.get(`/venues/${this.currentVenueId}/rooms`)
        .then((response) => {         
          this.rooms = response.data.data
        })
        .catch((error)   => { console.log(error) })
        .finally(() => {
          this.$store.dispatch('loader/loaderOff')
        })
    },
    editRoom(room) {
        this.isEditRoom = true;
        this.currentRoom = room;
        this.editRoomActive = true;
        
    },
    addRoom() {
        this.currentRoom = {
          gender_type: 'both',
          count: 1
        };
        this.editRoomActive = true;
        this.isEditRoom = false;
    },
  }
}
</script>

<style>

</style>