import { render, staticRenderFns } from "./OnBoarding.vue?vue&type=template&id=7d443eb7"
import script from "./OnBoarding.vue?vue&type=script&lang=js"
export * from "./OnBoarding.vue?vue&type=script&lang=js"
import style0 from "./OnBoarding.vue?vue&type=style&index=0&id=7d443eb7&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports